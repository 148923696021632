<template>
  <div>
    <el-dialog title="修改资源"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <el-form :model="form"
               ref="formRef"
               label-width="100px"
               :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="科目:"
                          prop="subject_id">
              <el-select v-model="form.subject_id"
                         style="width:100%"
                         placeholder="请选择">
                <el-option v-for="item in subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="教师权限:">
              <el-select v-model="form.edu_visible"
                         style="width:100%"
                         placeholder="请选择">
                <el-option :label="item.label"
                           v-for="item in option"
                           :key="item.value"
                           :value="item.value" />

              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="学生权限:">
              <el-select v-model="form.stu_visible"
                         @change="change"
                         style="width:100%"
                         placeholder="请选择">
                <el-option :label="item.label"
                           v-for="item in stuOption"
                           :key="item.value"
                           :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div v-if="form.stu_visible == 1">
          <el-row>
            <el-col :span="24">
              <el-form-item label="年级:">
                <el-select v-model="form.grade_ids"
                           @change="clearClass"
                           multiple
                           style="width:100%"
                           placeholder="请选择">
                  <el-option v-for="item in gradeList"
                             :key="item.grade_id"
                             :label="item.grade_name"
                             :value="item.grade_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item label="班级:">
                <el-select v-model="form.special_class_id"
                           multiple
                           @change="clearGrade"
                           style="width:100%"
                           placeholder="请选择">
                  <el-option v-for="item in classList"
                             :key="item.class_id"
                             :label="item.class_name + '-' + item.year_desc"
                             :value="item.class_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-row>
          <el-col :span="24">
            <el-form-item label="文件名称:">
              <el-input clearable
                        placeholder="请输入文件名称"
                        v-model="form.filename"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="是否下载:">
              <el-switch v-model="form.can_download"
                         active-value="1"
                         inactive-value="0"
                         active-text="开启下载"
                         inactive-text="关闭下载" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="描述">
              <el-input clearable
                        v-model="form.description"
                        placeholder="请输入描述" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="createSource">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {},
      rules: {
        subject_id: [
          { required: true, message: '请选择科目', trigger: 'blur' },
        ]
      },
      subjectList: [],
      gradeList: [],
      classList: [],
      dialogVisible: false,
      option: [
        { value: 1, label: '公共' },
        { value: 2, label: '本校' },
        { value: 3, label: '私有' },
      ],
      stuOption: [
        { value: 0, label: '所有学生' },
        { value: 1, label: '本校学生' },
      ]
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    spliceArr (index) {
      this.option.splice(index, 1)
      this.stuOption.splice(index, 1)

    },
    clearClass () {
      this.form.special_class_id = []
    },
    clearGrade () {
      this.form.grade_ids = []
    },
    change () {
      this.form.grade_ids = []
      this.form.special_class_id = []
    },
    getList () {
      this.$http({
        url: '/api/v1/school/select_grade',
        method: 'get'
      }).then(res => {
        this.gradeList = res.data.list
      })
      this.$http({
        url: '/api/v1/special/tc_lead_class',
        method: 'get'
      }).then(res => {
        this.classList = res.data
      })
    },
    handleClose () {
      this.option = [
        { value: 1, label: '公共' },
        { value: 2, label: '本校' },
        { value: 3, label: '私有' },
      ]
      this.stuOption = [
        { value: 0, label: '所有学生' },
        { value: 1, label: '本校学生' },
      ]
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    },
    createSource () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        form.grade_ids = form.grade_ids.join(',')
        form.special_class_ids = form.special_class_id.join(',')
        this.$http({
          url: '/api/v1/file/edit',
          method: 'post',
          data: form
        }).then(() => {
          this.$notify({
            title: '提示',
            message: '修改成功!',
            type: 'success'
          });
          this.handleClose()
          this.$parent.search()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}
::v-deep .dialog-footer {
  text-align: center;
}
.addFile {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  cursor: pointer;
  line-height: 146px;
  vertical-align: top;
  text-align: center;
}
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>